import { Spinner } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./App.css";
import { useAuth } from "./auth";
import { Header } from "./components/header";
import Login from "./components/login";
import { BulletinForm } from "./components/form";
import { useProvideAuth } from "./hooks/use-auth-provider";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { Home } from "./components/home";
import { Templates } from "./components/template";
import { GlobalLangList } from "./components/global-lang";
/* import { AtaTemplate } from "./components/templates/ata";
 */
/* import { HalkTemplate } from "./components/templates/halkbank";
 */ import React, { Suspense } from "react";
import { Speak } from "./components/speak";
import { Layout } from "./layout";
const AtaTemplate=React.lazy(() => import('./components/templates/ata'));
const HalkTemplate=React.lazy(() => import('./components/templates/halkbank'));
const InfoTemplate=React.lazy(() => import('./components/templates/info'));
const GlobalTemplate=React.lazy(() => import('./components/templates/global'));
function App() {
  const { isLoadingUser, authUser } = useAuth();
  if (isLoadingUser) return <Spinner />;
  if (authUser === null) return <Login />;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route path="home" element={<Home />} />
        <Route path="new" element={<BulletinForm />} />
        <Route path="templates" element={<Templates />} />
        <Route
          path="company/ata"
          element={
            <Suspense fallback={<Spinner />}>
              <AtaTemplate />{" "}
            </Suspense>
          }
        />
        <Route
          path="company/halkbank"
          element={
            <Suspense fallback={<Spinner />}>
              <HalkTemplate />{" "}
            </Suspense>
          }
        />
        <Route path="speak" element={<Speak />} />
        <Route path="global-lang" element={<GlobalLangList />} />
      </Route>
    )
  );
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/new" element={<BulletinForm />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/company/ata" element={<AtaTemplate />} />
        <Route path="/company/halkbank" element={<HalkTemplate />} />
        <Route path="/company/info" element={<InfoTemplate />} />
        <Route path="/company/global" element={<GlobalTemplate />} />

        <Route path="/speak" element={<Speak />} />

        <Route path="/global-lang" element={<GlobalLangList />} />
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
}

export default App;
