import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { useProvideAuth } from "../hooks/use-auth-provider";

export const Header = () => {
  const { userSignOut } = useProvideAuth();
  const navigate = useNavigate();
  return (
    <Navbar style={{ marginBottom: 10 }}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading onClick={() => navigate("/home")}>
          Sentiment Bülten
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Button
          minimal
          icon="home"
          text="Anasayfa"
          onClick={() => navigate("/home")}
        />
        <Button
          minimal
          icon="style"
          text="Tasarımlar"
          onClick={() => navigate("/templates")}
        />
         <Button
          minimal
          icon="pulse"
          text="Dinamik Tasarımlar"
          onClick={() => navigate("/company/ata")}
        />
        <Button
          minimal
          icon="translate"
          text="Diller"
          onClick={() => navigate("/global-lang")}
        />
        <Button
          onClick={() => {
            userSignOut();
          }}
          minimal
          icon="log-out"
          text="Çıkış"
        />
      </Navbar.Group>
    </Navbar>
  );
};
